<template>
  <div class="chatRoom">
    <div class="msg-list">
      <div style="width: 100%">
        <div style="cursor: pointer;text-align: center;padding: 4px;" @click="handleBack">
          卖家中心
        </div>
        <div class="msg-list_top">
          <span>买家消息</span>
          <el-select style="width: 200px" size="small" v-model="value" placeholder="请选择" @change="getMsgList">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="msg-list_top2">
          <el-input style="width: 220px" v-model="keyword" placeholder="请输入内容"></el-input>
          <el-button type="primary" @click="getMsgList">搜索</el-button>
        </div>
        <div class="msg-list_box" v-if="msgList.list && msgList.list.length != 0">
          <div @click="openSession(item,1)" class="msg-list_item" v-for="(item, i) in msgList.list" :key="i">
            <div style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;
              ">
              <div>{{ item.t_name }}</div>
              <div>{{ formatTimeA(item.add_time * 1000, 4) }}</div>
            </div>
            <div class="single-hidden" style="width: 100%">
              {{item.msg_status==0?
              item.message:item.msg_status==1?'[出价]':item.msg_status==2?'[商品推荐]':item.msg_status==3?'[优惠券]':'' }}
            </div>
          </div>
        </div>
        <div v-else style="text-align: center; padding-top: 50%">
          <img :src="img2" alt="" />
          <div style="font-size: 14px; color: #b0b0b0">暂无对话</div>
        </div>
      </div>
      <div style="width: 100%; " v-if="msgList.list.length">
        <div class="paging" style="padding: 10px 0;">
          <i class="el-icon-arrow-left icon" @click="handleLeft"></i>
          <div style="margin: -2px 4px 0; display: flex; align-items: center">
            {{ page }}
            <span style="font-size: 16px; margin: -2px 4px 0"> /</span>
            {{msgList.total==page_size?'1': (parseInt(msgList.total / page_size) + 1) }}
          </div>
          <i class="el-icon-arrow-right icon" @click="handleRight"></i>
        </div>
      </div>
    </div>
    <div class="msg-window">
      <div v-if="chatInfo" class="msg-window_box">
        <div class="msg-window_head">{{ chatInfo.t_name }}</div>
        <div class="rec"></div>
        <div class="textArea">
          <textarea @keypress="pushKeyword" placeholder="请将您的回复控制在4000个字符以内" name="" id="" maxlength="4000"
            v-model="msgVal"></textarea>
        </div>
        <div class="msg-window_foot">
          <span @click="openSession(chatInfo)"
            style="text-decoration: underline; color: #409eff; cursor: pointer">如您未收到回复信息，请点击此处获取</span>
          <div style="color: #b0b0b0; display: flex; align-items: center">
            <span style="margin-right: 10px"> {{ msgVal.length }}/4000</span>
            <el-button :disabled="msgVal == ''" type="primary" size="mini" @click="pushKeyword">发 送</el-button>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; padding-top: 25%">
        <img style="width: 300px" :src="img1" alt="" />
        <div style="font-size: 20px; color: #b0b0b0; margin-top: -4%">
          欢迎来到聊天
        </div>
      </div>
    </div>

    <div class="info-window">


      <div style="display: flex;align-items: center;border-bottom: 1px solid #eee;">
        <div @click="handleType(index)" style="width:50%;text-align: center;line-height: 48px;cursor: pointer;"
          :style="rType==index?'color:#ee4d2d':''" v-for="(item,index) in 2" :key="index">{{
          index==1?'优惠券':'商品'}}
          <div v-if="rType==index" style="width:100px;height:3px;background: #ee4d2d;margin: 0 auto;"></div>
        </div>
      </div>
      <div v-if="rType==0" style="margin-top: 10px;overflow-y: scroll;height:calc(100vh - 80px);">
        <div v-for="(item,index) in goodsList.list" :key="index"
          style="border-top: 1px  solid #eee;border-bottom: 1px  solid #eee;padding: 20px;margin-top: 10px;">
          <div style="display: flex;">
            <div>
              <img style="width:60px;height:60px;" :src="item.image" alt="">
            </div>
            <div style="margin-left: 14px;">
              <div class="row-hidden" style="word-break: break-all;font-size: 14px;">{{item.title}}</div>
              <div style="font-size: 16px;color: #ee4d2d;">RM{{item.price}}</div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;margin-top: 20px;align-items: center;">
            <div style="font-size: 14px;color: #999;">{{item.stock}}现货 | {{item.sales}} 售出</div>
            <div>
              <el-button size="small" @click="handleOpenGoodsInfo(item)">详情</el-button>
              <el-button size="small" type="primary" @click="handleSendGoods(item)">发送</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="margin-top:10px;overflow-y: scroll;height:calc(100vh - 80px);">
        <div v-for="item,index in couponsList.list" :key="index"
          style="border-top: 1px  solid #eee;border-bottom: 1px  solid #eee;padding: 20px;margin-top: 10px;">
          <div style="display: flex;">
            <div>
              <img style="width:60px;height:60px" :src="item.discount_coupon_type==1?icon1:icon2" alt="">
            </div>
            <div style="font-size: 13px;margin-left: 14px;">
              <div style="color: #ee4d2d;font-size: 16px;">10.00</div>
              <div>RM</div>
              <div>{{item.coupon_code}}</div>
              <div>最低消费金额{{item.min_consume_amount}} <span v-if="item.max_discount_amount!=0">|
                  最高优惠金额{{item.max_discount_amount}}</span> </div>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-top: 10px;justify-content: space-between;">
            <div style="font-size: 14px;color:#b0b0b0;margin-right: 10px;">
              领取期限：{{item.start_time*1000|formatTimeA(1)}}-{{item.end_time*1000 |formatTimeA(1)}}
            </div>
            <div> <el-button size="small" type="primary" @click="handleSendCoupons(item)">发送</el-button></div>
          </div>
        </div>
      </div>
    </div>



    <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div slot="title" style="text-align: center;color: #ee4d2d;">等待确认中</div>
      <div style="display: flex;margin-top: -30px;padding-bottom: 20px;border-top: 1px solid #eee;padding-top: 20px;">
        <img style="width:60px;height:60px;border-radius: 4px;" :src="bidGoodsInfo.image" alt="">
        <div style="margin-left: 10px;">
          <div>{{bidGoodsInfo.title}}</div>
          <div>RM{{bidGoodsInfo.price}}</div>
        </div>
      </div>
      <div
        style="border-top: 1px solid #eee;border-bottom: 1px solid #eee;font-size: 18px;color: #ee4d2d;text-align: center;line-height: 40px;">
        RM{{bidGoodsInfo.bid_price}}
      </div>
      <div style="display: flex;justify-content: space-between;margin-top: 10px;">
        <el-button style="width:48%" size="small" plain @click="handlebid(2)">拒绝出价</el-button>
        <el-button size="small" plain @click="handlebid(1)"
          style="width:48%;color: #ee4d2d;border-color: #ee4d2d;">接受出价</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { h, render } from 'vue';
  import {
    getMsgListSeller,
    getChatListSeller,
    addMsgSeller,
    getRightMsg
  } from "@/api/chat.js";

  export default {
    data() {
      return {
        dialogVisible: false,
        img1: require("@/assets/img/app/liao.png"),
        img2: require("@/assets/img/app/liao-list.png"),
        icon1: require('@/assets/img/shop/porp.png'),
        icon2: require('@/assets/img/shop/money.png'),
        value: 0,
        keyword: "",
        options: [
          {
            label: "所有消息",
            value: 0,
          },
          {
            label: "已读",
            value: 1,
          },
          {
            label: "未读",
            value: 2,
          },
        ],
        msgList: {},
        msgVal: "",
        page: 1,
        page_size: 9,
        msgInfo: { list: [] },
        chatInfo: "", // 聊天对象信息
        rType: 0,
        goodsList: {
          list: []
        },
        couponsList: {
          list: []
        },
        bidGoodsInfo: "",
        key: 1,
        mid: ''
      };
    },
    created() {
      this.getMsgList();
      this.getRightGoods()
      this.getRightCoupons()
      let _this = this;
      setInterval(function () {
        _this.getMsgList();
      }, 30000);
    },
    methods: {
      //   获取消息列表
      getMsgList() {
        // localStorage.USERTYPE = 2;
        getMsgListSeller({
          keyword: this.keyword,
          status: this.value,
          page: this.page,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code == 1) {
            this.msgList = res.data;

            if (this.chatInfo) {
              this.openSession(this.chatInfo);
            }
          }
        });
      },
      // 打开会话
      openSession(item, type) {
        // localStorage.USERTYPE = 2;
        if (item.m_id != this.chatInfo.m_id) {
          let box = document.getElementsByClassName("rec");
          if (box[0]) {
            box[0].innerHTML = "";
            this.msgInfo = { list: [] }
            this.mid = ''
          }
        }
        this.chatInfo = item;
        getChatListSeller({
          m_id: item.m_id,
          page: 1,
          page_size: 100,
        }).then((res) => {
          if (res.code == 1) {
            let arr = [];
            this.msgInfo.list.forEach((val) => {
              arr.push(val.m_id);
            });
            res.data.list.reverse();
            res.data.list.forEach((item) => {
              item.time = this.formatTime(item.time);
              if (arr.indexOf(item.m_id) == -1) {
                if (item.administrator == "right") {

                  if (item.msg_status == 0) {
                    this.addRightMsgBox(item);
                  }
                  if (item.msg_status == 1) {
                    this.addBidr(item)
                  }
                  if (item.msg_status == 2) {
                    this.sendGoods(item)
                  }
                  if (item.msg_status == 3) {
                    this.sendCoupons(item)
                  }
                }
                if (item.administrator == "left") {
                  this.addLeftMsgBox(item);
                  if (item.msg_status == 1) {
                    this.addBid(item)
                  }
                }
              } else {
                if (this.mid == item.m_id) {
                  let box = document.getElementById(item.m_id)
                  if (item.bid_status == 1) {
                    box.innerHTML = '已同意'
                  }
                  if (item.bid_status == 2) {
                    box.innerHTML = '已拒绝'
                  }
                  box.onclick = null
                }
              }
            });
            this.msgInfo = res.data;
          }
        });
      },

      // 发送消息
      pushKeyword(e) {
        if ("keyCode" in e == false || e.keyCode == 13) {
          e.preventDefault();
          if (this.msgVal != "") {
            // localStorage.USERTYPE = 2;
            addMsgSeller({
              m_id: this.chatInfo.m_id,
              message: this.msgVal,
              msg_status: 0,
              status: 0
            }).then((res) => {
              if (res.code == 1) {
                // 创建消息元素
                this.openSession(this.chatInfo);
                // this.addRightMsgBox(this.liaosd);
              }
            });
          }
        }
      },
      // 添加右侧信息
      addRightMsgBox(data) {
        let box = document.getElementsByClassName("rec");
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "right";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "50%";
        boxSon.style["text-align"] = "left";
        abc.appendChild(boxSon);
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "10px 20px";
        time.style["text-align"] = "right";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        let cbd = document.createElement("div");
        cbd.style["width"] = "auto";
        cbd.style["font-size"] = "14px";
        cbd.style.color = "#000";
        cbd.style.display = "flex";
        cbd.style["justify-content"] = "right";
        let str = document.createElement('div');
        str.style["width"] = "auto";
        str.style["border-radius"] = "4px";
        str.style.padding = "8px";
        str.style.background = "#D7E4FF";
        str.style["font-size"] = "14px";
        str.style.color = "#000";
        str.style.margin = "2px 20px";
        str.style.display = "inline-block";
        str.style['overflow-wrap'] = 'anywhere';
        str.innerHTML = data.message;
        cbd.appendChild(str);
        boxSon.appendChild(cbd);
        boxSon.setAttribute("id", "ccc");
        box[0].scrollTop = box[0].scrollHeight;
        this.msgVal = "";
      },
      // 添加左侧信息
      addLeftMsgBox(data) {
        let box = document.getElementsByClassName("rec");
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "left";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "50%";
        boxSon.style["text-align"] = " left";
        abc.appendChild(boxSon);
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "4px 20px";
        time.style["text-align"] = "left";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        let cbd = document.createElement("div");
        cbd.style["width"] = "auto";
        cbd.style["border-radius"] = "4px";
        cbd.style.padding = "8px";
        cbd.style.background = "#F5F6F8";
        cbd.style["font-size"] = "14px";
        cbd.style.color = "#000";
        cbd.style.margin = "2px 20px";
        cbd.style.display = "inline-block";
        cbd.style['overflow-wrap'] = 'anywhere';
        cbd.innerHTML = data.message;
        boxSon.appendChild(cbd);
        boxSon.setAttribute("id", "ccc");
        box[0].scrollTop = box[0].scrollHeight;

      },




      // 聊天发送商品
      sendGoods(data) {
        // 获取整个聊天框
        let box = document.getElementsByClassName("rec");
        // 创建写入右部内容的容器
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "right";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "50%";
        boxSon.style["text-align"] = "left";
        abc.appendChild(boxSon);
        // 创建写入消息时间
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "10px 20px";
        time.style["text-align"] = "right";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        // 创建写入商品容器
        let goods = document.createElement("div");
        goods.style.border = '4px solid #DCE5F4'
        goods.style.width = '400px'
        // goods.style.height = '140px'
        goods.style['margin-right'] = '20px'
        goods.style['border-radius'] = '4px'
        goods.style.padding = '10px'
        boxSon.appendChild(goods);
        // 写入容器标题
        let title = document.createElement('div');
        title.innerHTML = '商品'
        goods.appendChild(title)
        // 写入图片标题价格的容器
        let con = document.createElement('div');
        con.style.display = 'flex'
        con.style['margin-top'] = '10px'
        goods.appendChild(con)
        let img = document.createElement('img');
        img.style.width = '60px'
        img.style.height = '60px'
        img.src = data.goods.image
        let info = document.createElement('div');
        info.style['margin-left'] = '10px'
        let name = document.createElement('div');
        name.className = 'row-hidden'
        let jg = document.createElement('div');
        jg.style.color = '#ee4d2d'
        name.innerHTML = data.goods.title
        jg.innerHTML = 'RM' + data.goods.price
        info.appendChild(name)
        info.appendChild(jg)
        let foot = document.createElement('div')
        foot.style['display'] = 'flex'
        foot.style['justify-content'] = 'right'
        let btn = document.createElement('div')
        btn.style['text-align'] = 'right'
        btn.style['margin-top'] = '10px'
        btn.style['color'] = '#31B7F3'
        btn.style['cursor'] = 'pointer'
        btn.innerHTML = '商品详情'
        let _this = this
        btn.addEventListener('click', function () {
          // window.open('/goodsInfo?goodsId=' + data.goods.id)
          _this.$router.push('/goodsInfo?goodsId=' + data.goods.id)

        })
        foot.appendChild(btn)
        con.appendChild(img)
        con.appendChild(info)
        goods.appendChild(foot)

        abc.appendChild(boxSon);
        box[0].scrollTop = box[0].scrollHeight;

      },
      // 聊天发送优惠券
      sendCoupons(data) {
        // 获取整个聊天框
        let box = document.getElementsByClassName("rec");
        // 创建写入右部内容的容器
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "right";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "50%";
        boxSon.style["text-align"] = "left";
        abc.appendChild(boxSon);
        // 创建写入消息时间
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "10px 20px";
        time.style["text-align"] = "right";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        //  写入优惠券容器
        let con = document.createElement('div')
        con.style.border = '1px solid #666'
        con.style.padding = '10px'
        con.style['margin-right'] = '20px'
        con.style['border-radius'] = '4px'
        con.style['display'] = 'flex'
        let img = document.createElement('img')
        img.style.width = '60px'
        img.style.height = '60px'
        img.src = data.coupon.discount_coupon_type == 1 ? this.icon1 : this.icon2
        let desc = document.createElement('div');
        desc.style['margin-left'] = '20px'
        desc.style['padding-top'] = '10px'
        let name = document.createElement('div');
        name.style['font-weight'] = 'bold'
        name.style['color'] = '#666'

        if (data.coupon.discount_coupon_type == 1) {
          name.innerHTML = `${data.coupon.discount_amount}折扣 最低消费金额${data.coupon.min_consume_amount} ${data.coupon.max_discount_amount != 0 ? '| 最高优惠金额' + data.coupon.max_discount_amount : ''}`
        } else {
          name.innerHTML = `${data.coupon.discount_amount}折扣 最低消费金额${data.coupon.min_consume_amount}`

        }
        let day = document.createElement('div')
        day.style.color = '#999'
        day.style['font-size'] = '13px'
        day.style['margin-top'] = '10px'
        day.innerHTML = '有效期至:' + this.formatTimeA(data.coupon.start_time * 1000, 1)
        desc.appendChild(name)
        desc.appendChild(day)
        con.appendChild(img)
        con.appendChild(desc)

        boxSon.appendChild(con)
        box[0].scrollTop = box[0].scrollHeight;
      },

      handleType(index) {
        this.rType = index
      },


      // 获取右侧商品
      getRightGoods() {
        getRightMsg({
          type: 1,
          page: 1,
          page_szie: 100
        }).then(res => {
          if (res.code == 1) {
            this.goodsList = res.data
          }
        })
      },
      // 获取右侧优惠券
      getRightCoupons() {
        getRightMsg({
          type: 2,
          page: 1,
          page_szie: 100
        }).then(res => {
          if (res.code == 1) {
            this.couponsList = res.data

          }
        })
      },


      // 右侧发送商品
      handleSendGoods(item) {
        if (!this.chatInfo.m_id) {
          this.$message.error('请在左侧选择发送人')
          return false
        }
        addMsgSeller({
          m_id: this.chatInfo.m_id,
          message: item.id,
          status: 0,
          msg_status: 2
        }).then((res) => {
          if (res.code == 1) {
            // 创建消息元素
            this.openSession(this.chatInfo);
          }
        });
      },

      // 右侧发送优惠券
      handleSendCoupons(item) {
        if (!this.chatInfo.m_id) {
          this.$message.error('请在左侧选择发送人')
          return false
        }
        addMsgSeller({
          m_id: this.chatInfo.m_id,
          message: item.id,
          status: 0,
          msg_status: 3
        }).then((res) => {
          if (res.code == 1) {
            // 创建消息元素
            this.openSession(this.chatInfo);
          }
        });
      },
      handleOpenGoodsInfo(item) {
        // window.open()
        this.$router.push('/goodsInfo?goodsId=' + item.id)
      },

      // 左侧发送商品出价
      addBid(data) {
        let box = document.getElementsByClassName("rec");
        let abc = document.createElement("div");

        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "left";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "50%";
        boxSon.style["text-align"] = " left";
        abc.appendChild(boxSon);
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "4px 20px";
        time.style["text-align"] = "left";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        let con = document.createElement('div');
        con.style.border = '1px solid #666'
        con.style['margin-left'] = '20px'
        con.style['border-radius'] = '4px'
        con.style.width = '300px'
        let top = document.createElement('div');
        top.style.display = 'flex'
        let img = document.createElement('img');
        img.style.width = '50px';
        img.style.height = '50px';
        img.style['margin-left'] = '10px'
        img.style['margin-top'] = '10px'
        img.src = data.goods.image;
        let desc = document.createElement('div');
        desc.style['margin-left'] = '10px'
        desc.style['font-size'] = '13px';
        desc.style['padding-top'] = '10px'
        desc.style['padding-right'] = '10px'

        let text1 = document.createElement('div');
        text1.innerHTML = '出价'
        let text2 = document.createElement('div');
        text2.className = 'row-hidden'
        text2.style.margin = '4px 0'
        text2.innerHTML = data.goods.title
        let text3 = document.createElement('div');
        text3.style.color = '#ee4d2d'
        text3.innerHTML = 'RM' + data.goods.bid_price
        desc.appendChild(text1)
        desc.appendChild(text2)
        desc.appendChild(text3)
        top.appendChild(img)
        top.appendChild(desc)
        let bottom = document.createElement('div');
        bottom.style.background = '#ee4d2d'
        bottom.style.color = '#fff';
        bottom.style['font-size'] = '12px'
        bottom.style.padding = '2px 10px'
        bottom.style['margin-top'] = '10px'
        bottom.style['margin-top'] = '10px'
        bottom.setAttribute('id', data.m_id)
        if (data.bid_status == 0) {
          bottom.style['cursor'] = 'pointer'
          bottom.innerHTML = '点选以查看'

          // bottom.addEventListener('click', _this.fn(data))
          let _this = this
          bottom.onclick = function () {
            _this.mid = data.m_id
            _this.bidGoodsInfo = data.goods
            _this.dialogVisible = true
          }

        }
        if (data.bid_status == 1) {
          bottom.innerHTML = '已同意'
        }
        if (data.bid_status == 2) {
          bottom.innerHTML = '已拒绝'
        }





        con.appendChild(top)
        con.appendChild(bottom)
        boxSon.appendChild(con)
        box[0].scrollTop = box[0].scrollHeight;
      },
      // 右侧操作商品出价
      addBidr(data) {
        let box = document.getElementsByClassName("rec");
        let abc = document.createElement("div");
        abc.style.display = "flex";
        abc.style.alignItems = "center";
        abc.style.justifyContent = "right";
        box[0].appendChild(abc);
        let boxSon = document.createElement("div");
        boxSon.style["margin-bottom"] = "12px";
        boxSon.style["max-width"] = "50%";
        boxSon.style["text-align"] = " left";
        abc.appendChild(boxSon);
        let time = document.createElement("div");
        time.style["font-size"] = "12px";
        time.style.color = "#b0b0b0";
        time.style.margin = "4px 20px";
        time.style["text-align"] = "left";
        time.innerHTML = data.time;
        boxSon.appendChild(time);
        let con = document.createElement('div');
        con.style.border = '1px solid #666'
        con.style['margin-right'] = '20px'
        con.style['border-radius'] = '4px'
        con.style.width = '300px'
        let top = document.createElement('div');
        top.style.display = 'flex'
        let img = document.createElement('img');
        img.style.width = '50px';
        img.style.height = '50px';
        img.style['margin-left'] = '10px'
        img.style['margin-top'] = '10px'
        img.src = data.goods.image;
        let desc = document.createElement('div');
        desc.style['margin-left'] = '10px'
        desc.style['font-size'] = '13px';
        desc.style['padding-top'] = '10px'
        desc.style['padding-right'] = '10px'

        let text1 = document.createElement('div');
        text1.innerHTML = data.bid_status == 1 ? '已同意' : '已拒绝'
        let text2 = document.createElement('div');
        text2.className = 'row-hidden'
        text2.style.margin = '4px 0'

        text2.innerHTML = data.goods.title

        let text3 = document.createElement('div');
        text3.style.color = '#ee4d2d'
        text3.innerHTML = 'RM' + data.goods.bid_price
        desc.appendChild(text1)
        desc.appendChild(text2)
        desc.appendChild(text3)
        top.appendChild(img)
        top.appendChild(desc)
        let bottom = document.createElement('div');
        bottom.style.background = '#ee4d2d'
        bottom.style.color = '#fff';
        bottom.style['font-size'] = '12px'
        bottom.style.padding = '2px 10px'
        bottom.style['margin-top'] = '10px'
        bottom.style['margin-top'] = '10px'
        if (data.bid_status == 0) {
          bottom.style['cursor'] = 'pointer'
          bottom.innerHTML = '点选以查看'
          let _this = this
          bottom.addEventListener('click', function () {
            _this.bidGoodsInfo = data.goods
            _this.dialogVisible = true
          })
        }
        if (data.bid_status == 1) {
          bottom.innerHTML = '已同意'
        }
        if (data.bid_status == 2) {
          bottom.innerHTML = '已拒绝'
        }

        con.appendChild(top)
        con.appendChild(bottom)
        boxSon.appendChild(con)
        box[0].scrollTop = box[0].scrollHeight;

      },
      // 处理出价
      handlebid(type) {
        let text = type == 1 ? '接受' : '拒绝'
        this.$confirm(`是否${text}买家出价？`, '系统提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          addMsgSeller({
            m_id: this.chatInfo.m_id,
            message: this.bidGoodsInfo.id,
            status: type,
            msg_status: 1
          }).then((res) => {
            if (res.code == 1) {
              // 创建消息元素
              this.dialogVisible = false;
              this.openSession(this.chatInfo);
            }
          });
        })


      },
      handleClose() {
        this.dialogVisible = false
      },
      handleBack() {
        this.$router.replace('shopCenter')
      },
      handleLeft() {
        if (this.page > 1) {
          this.page--
          this.getMsgList()
        }
      },
      handleRight() {
        if (this.page != (parseInt(this.msgList.total / this.page_size) + 1)) {
          this.page++
          this.getMsgList()
        }
      }


    },


  };

</script>
<style lang="less" scoped>
  .chatRoom {
    display: flex;
    min-height: 600px;
    height: 100%;

    .msg-list {
      width: 21%;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      // background: red;
      .msg-list_top {
        padding: 10px;
        background: #ee4d2d;
        color: #fff;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
      }

      .msg-list_top2 {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .msg-list_box {
        height: calc(100vh - 182px);
        overflow-y: scroll;

        .msg-list_item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 63px;
          border-bottom: 1px solid #ddd;
          font-size: 13px;
          padding: 8px 20px;
          box-sizing: border-box;
          cursor: pointer;
        }

        .msg-list_item:hover {
          background: #ebeced;
        }
      }

      .paging {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-top: 1px solid #ddd;
        box-sizing: border-box;
        color: #333;

        .icon {
          cursor: pointer;
        }
      }
    }

    .msg-window {
      width: 56%;
      height: 100%;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;

      .msg-window_box {
        width: 100%;
        height: 100%;
        min-width: 600px;
        position: relative;

        // background: yellow;
        .msg-window_head {
          font-size: 16px;
          font-weight: bold;
          padding: 10px 20px;
          border-bottom: 1px solid #ddd;
        }

        .rec {
          border-bottom: 1px solid #eee;
          height: 68%;
          overflow: scroll;
          overflow-x: hidden;
        }

        .rec::-webkit-scrollbar {
          width: 4px;
          /*设置滚动条的宽度*/
        }

        .rec::-webkit-scrollbar-thumb {
          border-radius: 10px;
          /*设置滚动条的圆角*/
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          /*设置内阴影*/
          background: #b0b0b0;
          /*设置滚动条的颜色*/
        }

        .textArea {
          width: 100%;
          height: 18%;
          padding: 0 20px;
          position: absolute;
          bottom: 40px;
          border-bottom: 1px solid #eee;

          textarea {
            width: 100%;
            height: 100%;
            border: none;
            font-size: 16px;
            resize: none;
          }

          textArea::-webkit-scrollbar {
            width: 4px;
            /*设置滚动条的宽度*/
          }

          textArea::-webkit-scrollbar-thumb {
            border-radius: 10px;
            /*设置滚动条的圆角*/
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            /*设置内阴影*/
            background: #b0b0b0;
            /*设置滚动条的颜色*/
          }
        }
      }

      .msg-window_foot {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px 20px;
        font-size: 12px;
        position: absolute;
        bottom: 0;
      }
    }

    .info-window {
      width: 23%;
      min-width: 300px;
      // background: green;
    }
  }
</style>